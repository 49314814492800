import { buildPtusFrontendUrl } from '@repairer-frontend/ptus-frontend-url-builder';

export const BASE_URLS = {
    jobs: '/jobs',
    returns: '/returns',
};

export const PAGES = {
    jobs: 'jobs',
    jobdetail: 'jobdetail',
    jobDetailInstantOrder: 'jobdetail',
    reports: buildPtusFrontendUrl('Reporting/ReportBusinessPerformanceList'),
    feedback: buildPtusFrontendUrl('CompanyFeedback/FeedbackAssigned'),
    settings: buildPtusFrontendUrl('Profile/MyCompany/RepairerSettings'),
    activity: buildPtusFrontendUrl('AllActivity'),
    returns: 'returns',
};

export const PATHS = {
    /**
     * job MFE: Jobs dashboard
     */
    jobs: `${BASE_URLS.jobs}/active-jobs`,
    /**
     * jobdetail MFE: List of parts page
     */
    jobdetail: `/job/:id`,
    /**
     * returns MFE: Returns dashboard
     */
    returns: '/returns',
    /**
     * returns MFE: Returns detail page
     */
    returnsDetail: '/returns/:returnId',
    /**
     * returns MFE: Create a new return page
     */
    returnsNewWithPart: '/returns/new/:orderId/:orderPartId',

    jobDetailInstantOrder: `/job/:id/instantorder`,
};
